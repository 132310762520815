import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import styles from '../styles/carousel.module.scss';

const CarouselComponent = ({ body, image }) => {
  return <div className={styles.carousel}>
    <div className={styles.intro}>
      {!! body && <div className={styles.introInnerContainer}>
        <div
          className={styles.html}
          dangerouslySetInnerHTML={{
            __html: body.childMarkdownRemark.html,
          }}
        />
      </div>}
    </div>
    <div className={styles.image}>
      <Img {...image} />
    </div>
  </div>
}

const Carousel = () => (
  <StaticQuery
    query={graphql`
      query {
        content: contentfulContent(slug: { eq: "homepage-intro" }) {
          id
          body {
            childMarkdownRemark {
              html
            }
          }
          image {
            fluid(maxWidth: 1440, toFormat: JPG, quality: 75) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    `}
    render={data => <CarouselComponent { ...data.content } />}
  />
)

export default Carousel;
