import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Product from './product';

import '../styles/products.scss';

const Products = ({ id, data: { allContentfulProduct: { edges }} }) => {
  const products = edges.map(edge => <Product
    key={edge.node.id}
    slug={edge.node.slug}
    image={!!edge.node.image && (<Img {...edge.node.image} />)}
    description={edge.node.excerpt.excerpt}
  />)

  return <div className='products' id={id}>
    <div className="products--header">
      <h2>Onze producten</h2>
    </div>
    <div className='products--list'>
      {products}
    </div>
  </div>
};

Products.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    allContentfulProduct: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
}

export default (props) => (
  <StaticQuery
    query={productsQuery}
    render={data => <Products data={data} {...props} />}
  />
)

const productsQuery = graphql`
  query Products {
    allContentfulProduct {
      edges {
        node {
          id
          slug
          title
          image {
            id
            title
            fluid(maxWidth: 640, toFormat: JPG, quality: 75) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
          excerpt {
            id
            excerpt
          }
        }
      }
    }
  }
`
