import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Carousel from '../components/carousel';
import Products from '../components/products';
import Hat from '../components/hat';
import Story from '../components/story';
import Recipes from '../components/recipes';
import Stores from '../components/stores';

const IndexPage = () => {
  return <Layout>
    <SEO title='Hallo' keywords={[]} />
    <Carousel />
    <Story id='het-verhaal' />
    <Hat />
    <Products id='producten' />
    <Hat />
    <Recipes id='recepten' />
    <Hat />
    <Stores id='verkooppunten' />
  </Layout>
}

export default IndexPage
