import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Product = ({ description, slug, image }) => (
  <div className='products--list--item'>
    <Link to={`/producten/${slug}`}>
			<div className="products--list--item--image">
			{!!image && image}
			</div>
      <p>{description}</p>
    </Link>
  </div>
)

Product.propTypes = {
  description: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  image: PropTypes.node,
}

export default Product
