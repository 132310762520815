import React from 'react';
import PropTypes from 'prop-types';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import '../styles/story.scss';

const Story = ({ id }) => (
  <StaticQuery
    query={graphql`
      query {
        story: contentfulContent(slug: { eq: "de-mannen-van-ministry-of-spicy-business" }) {
          id
          title
          body {
            childMarkdownRemark {
              html
            }
          }
          image {
            fluid(maxWidth: 640, toFormat: JPG, quality: 75) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    `}
    render={data => (
      <div
        id={id}
        className='story'
      >
        <div className="column">
          <h2>{data.story.title}</h2>
          <div
            style={{whiteSpace: 'pre-line'}}
            dangerouslySetInnerHTML={{
              __html: data.story.body.childMarkdownRemark.html,
            }}
          />
          <p>
            <Link to='/ministry-of-spicy-business'>
              Lees meer...
            </Link>
          </p>
        </div>
        <div className="column">
          <Img {...data.story.image} />
        </div>
      </div>
    )}
  />
)

Story.propTypes = {
    id: PropTypes.string,
}

export default Story
